import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Snackbar } from '@material-ui/core';
import Layout from '../layouts/layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BlogReadMore from '../components/BlogReadMore/BlogReadMore';
import Blog from '../models/Blog';
import ICON_SHARE from '../images/share.png';
import './BlogTemplate.scss';

interface Props {
  data: {
    blog: Blog;
    more: { edges: { node: Blog }[] };
  };
}

const BlogTemplate = ({
  data: {
    blog: {
      frontmatter: { title, slug, date, banner },
      html,
    },
    more,
  },
}: Props): JSX.Element => {
  const [showCopied, setShowCopied] = useState(false);
  return (
    <Layout title={title} path={slug}>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showCopied}
        autoHideDuration={6000}
        onClose={() => setShowCopied(false)}
        message="Link copied!"
      />
      <div className="blog-post">
        <div className="blog-post-container">
          <div className="blog-post-indent">
            <div className="blog-post-header">
              <h2>{title}</h2>
              <div className="blog-post-date">
                <p>{date}</p>
                <button
                  type="button"
                  onClick={() => navigator.clipboard.writeText(window.location.href).then(() => setShowCopied(true))}
                >
                  <img src={ICON_SHARE} alt="Share" />
                </button>
              </div>
            </div>
          </div>
          {!!banner?.childImageSharp && (
            <div className="blog-post-banner">
              <GatsbyImage
                image={banner.childImageSharp.gatsbyImageData}
                className="blog-post-banner-img"
                alt={title}
              />
            </div>
          )}
          <div className="blog-post-indent">
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <hr className="blog-post-hr" />
          <BlogReadMore blogs={more.edges.map(({ node }) => ({ ...node }))} />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    blog: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D MMM YYYY")
        slug
        title
        banner {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    more: allMarkdownRemark(
      filter: { frontmatter: { slug: { ne: $slug } } }
      sort: { fields: frontmatter___order, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            banner {
              childImageSharp {
                gatsbyImageData(width: 640, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogTemplate;
