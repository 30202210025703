import { Link } from 'gatsby';
import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Blog from '../../models/Blog';
import './BlogReadMore.scss';
import HorizontalScrollbar from '../HorizontalScrollbar/HorizontalScrollbar';

interface Props {
  blogs: Blog[];
}

const BlogReadMore = ({ blogs }: Props): JSX.Element => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  return blogs.length > 0 ? (
    <div className="blog-read-more">
      <p className="blog-read-more-title">Read more...</p>
      <div className="blog-read-more-blogs" ref={targetRef}>
        {blogs.map(({ id, frontmatter: { slug, banner, title } }) => (
          <Link className="blog-read-more-card" key={id} to={slug}>
            {!!banner?.childImageSharp && (
              <div className="blog-read-more-card-banner">
                <GatsbyImage
                  image={banner.childImageSharp.gatsbyImageData}
                  className="blog-read-more-card-banner-img"
                  alt={title}
                />
              </div>
            )}
            <p className="blog-read-more-card-title">{title}</p>
          </Link>
        ))}
      </div>
      <HorizontalScrollbar targetRef={targetRef} />
    </div>
  ) : (
    <div className="blog-read-more" />
  );
};

export default BlogReadMore;
